import {initialPortfolioWidgets} from "stores/portfolioWidgets/constants";
import shortid from "shortid";



// { i: 'AUM', x: 0, y: 0, w: 2, h: 2, static: false },
// { i: 'groups-distribution', x: 0, y: 2, w: 1, h: 2, static: false },
// { i: 'risk-classification', x: 1, y: 2, w: 1, h: 2, static: false },
// { i: 'liquidity-distribution', x: 0, y: 4, w: 1, h: 2, static: false },
// { i: 'currency-exposure', x: 1, y: 4, w: 1, h: 2, static: false },
// { i: 'sector-distribution', x: 0, y: 6, static: false, ...initialPortfolioWidgets['sector-distribution'].sizes.paper },

export const blankPage = {
  id: shortid.generate(),
  name: 'New Page',
  headline: 'New Page',
  displayHeaderBanner: true,
  displayFooter: true,
  widgets: []
}
export const defaultPagesContent = {
    "1" : {
        id: 1,
        displayHeaderBanner: true,
        displayFooter: true,
        headline: "Resumé",
        name: 'Page 1',
        widgets: [
          { i: 'AUM', x: 0, y: 0, w: 3, h: 2, static: false },
          { i: 'groups-distribution', x: 0, y: 2, w: 1, h: 2, static: false },
          { i: 'risk-classification', x: 1, y: 2, w: 1, h: 2, static: false },
          { i: 'liquidity-distribution', x: 0, y: 4, w: 1, h: 2, static: false },
          { i: 'currency-exposure', x: 1, y: 4, w: 1, h: 2, static: false },
          { i: 'sector-distribution', x: 0, y: 6, static: false, w: 2, h: 3 },
        ]
    },
    // "2" : {
    //     id: 2,
    //     name: 'Page 2',
    //     displayHeaderBanner: true,
    //     displayFooter: true,
    //     headline: "Holding",
    //     widgets: [
    //         { i: 'holding', x: 0, y: 0, w: 3, h: Infinity, static: true },
    //     ]
    // }
}

  export const defaultPagesList = ["1"]
  
  /* SPECIFIC FOR PDF EDITOR
      pdf: {
        pages: {
          "resume_page_xyz": {
            id: "resume_page_xyz",
            title: "Resume",
            layout: {
              paper: [
                { i: 'AUM', x: 0, y: 0, w: 2, h: 2, static: false },
                { i: 'groups-distribution', x: 0, y: 2, w: 1, h: 2, static: false },
                { i: 'risk-classification', x: 1, y: 2, w: 1, h: 2, static: false },
                { i: 'liquidity-distribution', x: 0, y: 4, w: 1, h: 2, static: false },
                { i: 'currency-exposure', x: 1, y: 4, w: 1, h: 2, static: false },
                { i: 'sector-distribution', x: 0, y: 6, static: false, ...initialPortfolioWidgets['sector-distribution'].sizes.paper },
              ]
            }
          },
          "holding_page_dml": {
            id: "holding_page_dml",
            title: "Holding",
            layout: {
              paper: [
                { i: 'holding', x: 0, y: 0, w: 2, h: 2, static: false },
              ]
            }
          }
        },
        settings: {}
      }
  */

export const defaultPDFReport = {
    id: 'mkclds233-e321312-32132112dfw',  // ReportId
    customerId: 100,                      // CustomerId    
    portfolioId: 'Portfolio_Example',     // PortfolioId
    organisationId: 13651,                // OrganisationId
    emplyeeId: 13651,                     // EmployeeId
    name: "Report 1",
    AccountId: 123456789,
    pagesList: defaultPagesList,
    pagesContent: defaultPagesContent,
    settings: {
        bodyMargin: 10,
        includeAccountId: true,
        includeDatePeriod: true,
        horizontalLogoPosition: "left",
        verticalLogoPosition: "top",
        displayBackgroundImage: true,
        logoSize: 114,
        unit: "mio",
        period: "YTD",
    }
}