import { action, makeObservable, observable, toJS, runInAction } from "mobx";
// import PDFEditorSettingsStore from "../PDFEditorSettings";
import { blankPage, defaultPDFReport } from "./constants";
import shortid from "shortid";
import { initialPortfolioWidgets } from "../portfolioWidgets/constants";
import pdfEditorSettingsStore from "../PDFEditorSettings/PDFEditorSettingsStore";
import portfolioWidgetsStore from "../portfolioWidgets/portfolioWidgetsStore";
import portfoliosStore from "../portfoliosStore";
import { pdfWidgetBaseData } from "stores/portfolioWidgets/constants";
import { internalapiCall } from "api/call";
import { IRISK_BO_API_URL } from "api/constants";

// import { ROW_HEIGHT } from "../../Table/constants";
// import { setPageDataHeight } from "./utils";

class PDFEditorStore {
    report = defaultPDFReport;
    hasInitializedReport = false;
    // hasRunTasks = false;

    constructor() {
        makeObservable(this, {
            report: observable,
            hasInitializedReport: observable,
            addPage: action,
            initialize: action,
            updateLayout: action,
            addWidgetToLayout: action,
            setReportPeriod: action,

            // checkAndAdjustLayout: action,
        })
    }

    async initialize(params) {
        this.hasInitializedReport = true;
        
        // Get organisationID from organisationSettingsStore
        const organisationId = JSON.parse(localStorage.getItem("ORGANISATIONID"))
        // get UserId from userStore
        const userId = JSON.parse(localStorage.getItem("USER")).id

        const URL = `${IRISK_BO_API_URL}/report/pdf/${organisationId}/${userId}`
        console.table(["START PDF INIT", URL, params])

        const pdfReport = await internalapiCall("GET", URL)

        if(!pdfReport || !pdfReport.data || !pdfReport.data.data) {
            console.log("NO REPORT FOUND")
            return;
        }
        runInAction(() => {
            this.report = pdfReport.data.data
        })

        if(params.customerId && params.portfolioId && portfoliosStore.fetchedId !== params.portfolioId) {
            console.log("FETCHING PORTFOLIO DETAILS")
            await portfoliosStore.fetchCustomerPortfolioDetails(params.customerId, params.portfolioId, this.report.settings.startDate, this.report.settings.endDate);
        }
        // First we fetch calculations
        if(params.customerId && params.portfolioId && portfolioWidgetsStore.fetchedId !== params.portfolioId) {
            console.log("FETCHING DYNAMIC WIDGETS")
            await portfolioWidgetsStore.initializeDynamicWidgets(params.customerId, params.portfolioId)
            portfolioWidgetsStore.setFetchedId(params.portfolioId)
        }

        runInAction(() => {
            const copyState = toJS(this.report)
            copyState.pagesList.forEach((pageId) => {
                const page = copyState.pagesContent[pageId]
                page.widgets = page.widgets.map((widget) => {
                    return {...widget, ...pdfWidgetBaseData }
                })
            })
            pdfEditorSettingsStore.initialize(this.report.settings);

            if(this.report.pagesList.length === 0) {
                this.addPage();
            }

            this.hasInitializedReport = false;
        });
    }

    updatePageHeadline(pageId, newHeadline) {
        this.report.pagesContent[pageId].headline = newHeadline;
    }

    async updateLayout(pageId, newLayout) {
        console.log("UPDATE LAYOUT", this.report)
        runInAction(() => {
            this.report.pagesContent[pageId].widgets = newLayout
        })
        if(this.report && this.report._id) {
            await internalapiCall("POST", `${IRISK_BO_API_URL}/report/pdf/update-layout`, { reportId: this.report._id, pageId: pageId, widgets: newLayout })
        }
    }

    // setSelectedPageId(pageId) {
    //     this.selectedPageId = pageId;
    // }

    addWidgetToLayout(widget, pageId) {
        const widgetJS = toJS(widget)
        const widgetItem = { i: widgetJS.id, x: 0, y: Infinity, static: false, ...widgetJS.sizes.paper };

        this.report.pagesContent[pageId].widgets.push(widgetItem)
    }

    addPage(prevPageIndex = null, page = null) {
        const pageId = shortid.generate()
        if(prevPageIndex !== null) {
            // add page to the right index in the pagesList'
            this.report.pagesList.splice(prevPageIndex + 1, 0, pageId);
        } else {
            this.report.pagesList.push(pageId);
        }
        const pageToAdd = page === null ? blankPage : page;
        this.report.pagesContent[pageId] = { ...pageToAdd, id: pageId };
        return pageId;
    }

    createHoldingPages(pageIndex, rowsForCurrentPage, holdingData){
        
        const programmedHoldingID = `holding_${pageIndex}`;
        // const rowsForCurrentPage = rowsStateCopy.slice(0, numOfRowsWithinPage);
        // rowsStateCopy = rowsStateCopy.slice(numOfRowsWithinPage);
    
        // Create a new page
        const newHoldingPage = {
            id: shortid.generate(),
            name: 'Holding',
            headline: 'Holding',
            displayHeaderBanner: true,
            displayFooter: true,
            widgets: []
          }
        const currentPageId = this.addPage(null, newHoldingPage);
        const currentPage = this.report.pagesContent[currentPageId];
    
        // Add widget to that page
        currentPage.widgets.push({
            i: programmedHoldingID,
            x: 0,
            y: 0,
            w: 3,
            h: Infinity,
            static: false
        });
    
        // Create a new system generated widget
        portfolioWidgetsStore.injectWidgetData(programmedHoldingID, {
            ...holdingData,
            rows: rowsForCurrentPage,
        });
    
        portfolioWidgetsStore.widgets[programmedHoldingID] = {
            ...initialPortfolioWidgets.holding,
            id: programmedHoldingID,
        };
    }

    setReportPeriod(period) {
        this.report.settings.period = period;
    }
}

const store = (window.pdfEditorStore = new PDFEditorStore());

export default store