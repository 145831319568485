import assetStore from "stores/assetStore";
import assumptionStore from 'stores/assumptionStore';
import organisationSettingsStore from "./organisationSettingsStore";
import benchmarksStore from 'stores/benchmarksStore';
import customersStore from 'stores/customersStore';
import navigationStore from 'stores/navigationStore';
import portfoliosStore from 'stores/portfoliosStore';
import snackbarStore from 'stores/snackbarStore';
import cashFlowStore from "./CashFlow"
import dataStore from "./dataStore";
import transactionsStore from "./transactionsStore";
import assetReturnStore from "./assetReturnStore";
import assetsOverviewStore from "./assetsOverviewStore";
import pricesAssetsStore from "./pricesAssetsStore";
import proxyAssetStore from "./proxyAssetStore";
import privateEquityAssetStore from "./privateEquityAssetStore";
import realTimeAssetsStore from "./realTimeAssetsStore";
import etfLoopthroughAssetStore from "./etfLoopthroughAssetStore";
import assetAllocationStore from "./assetAllocationStore";
import indicesStore from "./indicesStore";
import onboardingStore from "./onboardingStore";
import riskLimitRulesStore from "./riskLimitRulesStore";
import riskLimitBreachesStore from "./riskLimitBreachesStore";
import swapCurvesStore from "./yieldCurvesStore";
import portfolioHistoricalCalculationsStore from "./portfolioHistoricalCalculationsStore";
import clientSettingsStore from "./clientSettingsStore";
import swapMatrixStore from "./swapMatrixStore";
import portfolioWidgetsStore from "./portfolioWidgets";
import pdfEditorSettingsStore from "./PDFEditorSettings";
import pdfEditorStore from "./PDFEditor";
import dashboardEditorStore from "./DashboardEditor";

const stores = {
    customers: customersStore,
    portfolios: portfoliosStore,
    benchmarks: benchmarksStore,
    assumption: assumptionStore,
    organisationSettings: organisationSettingsStore,
    portfolioHistoricalCalculations: portfolioHistoricalCalculationsStore,
    snackbar: snackbarStore,
    onboarding: onboardingStore,
    navigation: navigationStore,
    asset: assetStore,
    cashFlow: cashFlowStore, 
    data: dataStore,
    transactions: transactionsStore,
    assetReturn: assetReturnStore, 
    assetsOverview: assetsOverviewStore,
    realTimeAssets: realTimeAssetsStore,
    indices: indicesStore,
    pricesAsset: pricesAssetsStore,
    proxyAsset: proxyAssetStore,
    assetAllocation: assetAllocationStore,
    privateEquityAsset: privateEquityAssetStore,
    etfLoopthroughAsset: etfLoopthroughAssetStore,
    riskLimitRules: riskLimitRulesStore,
    riskLimitBreaches: riskLimitBreachesStore,
    yieldCurves: swapCurvesStore,
    clientSettings: clientSettingsStore,
    swapMatrix: swapMatrixStore,
    portfolioWidgets: portfolioWidgetsStore,
    pdfEditorSettings: pdfEditorSettingsStore,
    pdfEditor: pdfEditorStore,
    dashboardEditor: dashboardEditorStore,
};

export default stores;
