// This store is going to have data for the widgets that we are going to use in our application.
// Initially we will only do repporting on each portfolio, but we can expand this to other areas of the application later on.
import { APIS, API_URL } from "api/constants";
import { action, makeObservable, observable, runInAction } from "mobx";
import { initialPortfolioWidgets } from "./constants";
import { constructDynamicWidgetSize } from "./utils";
import call from 'api/call';
import snackbarStore from "stores/snackbarStore";
import portfoliosStore from "stores/portfoliosStore";
import { dateToInt } from "utils/dateFormatters";
import PDFEditorStore from "stores/PDFEditor";
import { getAUMRelatedWidgets, getDataTypeFromCategory } from "./utils";
import DashboardEditorStore from "stores/DashboardEditor";
import dataStore from "stores/dataStore";
import { getDateRangeByNumberFromPeriod } from "utils/dateFormatters";

class PortfolioWidgetsStore {
    loading = false                       // 
    fetchedId = null
    error = null
    widgets = initialPortfolioWidgets
    widgetsMenu = {}
    widgetsData = {}
    reportItem = null
    breakpoint = null
    startDate = null
    endDate = null

    constructor() {
        makeObservable(this, {
            initializeDynamicWidgets: action,
            selectWidget: action,
            widgetsMenu: observable,
            widgets: observable,
            addDynamicWidget: action,
            loading: observable,
            fetchedId: observable,
            error: observable,
            widgetsData: observable,
            injectWidgetData: action,
            reportItem: observable,
            updateReportItemLayout: action,
            removeWidgetFromLayout: action,
            setBreakpoint: action,
            changeCalcDate: action,
            breakpoint: observable,
            startDate: observable,
            endDate: observable,
            setFetchedId: action,
        });
    }

    setBreakpoint(breakpoint) {
      this.breakpoint = breakpoint
    }


    setFetchedId(id) {
      this.fetchedId = id
    }

    addDynamicWidget(widget) {

      // Avoid dublicates
      if(this.widgets[widget.id]) {
        return
      }

      this.widgets = {
        ...this.widgets,
        [widget.id]: widget
      }
    }

    updateReportItemLayout(newLayout) {
      const updatedLayout = {
        ...this.reportItem,
        layout: {
          ...this.reportItem.layout,
          [this.breakpoint]: newLayout
        }
      }

      this.reportItem = updatedLayout
    }


    removeWidgetFromLayout(widgetId, breakpoint) {
      const updatedLayout = {
        ...this.reportItem,
        layout: {
          ...this.reportItem.layout,
          [breakpoint]: this.reportItem.layout[breakpoint].filter(widget => widget.i !== widgetId)
        }
      }

      this.reportItem = updatedLayout
    }

    injectWidgetData(widgetId, data) {
      const widgetDataElement = {
        ...this.widgetsData,
        [widgetId]: data
      }
      this.widgetsData = widgetDataElement
    }

    selectWidget(newWidget, reportType, pageId = null) {
      if(reportType === 'pdf') {
        PDFEditorStore.addWidgetToLayout(newWidget, pageId)
      }
      if(reportType === "dashboard") {
        DashboardEditorStore.addWidgetToLayout(newWidget)
      }
      // TODO: Layout for a widget might be in a store
      // Then we can add the widget to the layout
      // When constructing the menu, we can then 
      // check if the widget is in the layout and give it a checkmark
      // const selectedItem = this.widgets[newWidget.id]
      // fetch from dynamic url and set the data in the store
      // const foundWidgetInLayout = this.reportItem.layout[this.breakpoint].find((widget) => widget.i === newWidget.id)
      // if(foundWidgetInLayout) { // Remove widget from layout
      //     // TODO remove widget from widgetLayout
      //     this.removeWidgetFromLayout(newWidget.id, this.breakpoint)
      // } else {
      //     this.addWidgetToLayout(newWidget, this.breakpoint)
      // }
    }

    async changeCalcDate(customerId, portfolioId, newDate, key) {
      this[key] = dateToInt(newDate)
      
      await portfoliosStore.fetchCustomerPortfolioDetails(customerId, portfolioId, this.startDate, this.endDate);
    }

    

    async initializeDynamicWidgets(customerId, portfolioId) {
      // TODO: Fetch data for the widgets (including AUM for example)
        runInAction(() => {
          this.loading = true;
        });
        try {          
          const response = await call(APIS.GET_CALCULATION_RESULT_HIST_CAT, { CustomerID: Number(customerId), PortfolioName: portfolioId });
          let widgetsCopy = { ...this.widgets }

          if(!response.message.Category) {
            runInAction(() => {
              this.widgets = widgetsCopy
              this.loading = false
              // this.widgetsMenu
            });

            return
          }
          
          await response.message.Category.forEach(({CalcName, Category}) => {

            if(CalcName === 'AUM') {
              widgetsCopy = getAUMRelatedWidgets(widgetsCopy, CalcName, Category)
            } else {
              const foundWidget = widgetsCopy[CalcName]
              if(!foundWidget) {
                widgetsCopy = {
                  ...widgetsCopy,
                  [CalcName]: {}
                }
              }
                
              widgetsCopy[CalcName] = {
                id: CalcName,
                headline: CalcName.split('_').join(' '),
                category: Category,
                dataType: getDataTypeFromCategory(Category),
                independentFetchingURL: 'iRisk_GetCalculationResultHist',
                paths: {
                  chart: 'HistData',
                  investAmount: 'InvestAmount',
                },
                sizes: constructDynamicWidgetSize(CalcName, Category)
              }
            }
              runInAction(() => {
                this.widgets = widgetsCopy
                // this.widgetsMenu
              });

          })
        

          runInAction(() => {
            this.loading = false;
          });
          
        } catch (error) {
          snackbarStore.handleOpen(error.toString(), 'error');
          runInAction(() => {
            this.loading = false;
            this.error = error;
          });
        }
    }

    // async constructWidgetsMenu(customerId, portfolioId, reportType) {
    // //   if(portfoliosStore.fetchedId !== portfolioId) {
    // //     await portfoliosStore.fetchCustomerPortfolioDetails(customerId, portfolioId);
    // // }
    // //   if(this.fetchedId !== portfolioId) {
    // //     await this.initializeDynamicWidgets(customerId, portfolioId);
    // //   }

    //   const widgetsByCategory = getCategorizedWidgets(getWidgetIds(this.widgets));

    //     const widgetsMenu = {
    //         label: 'Widgets',
    //         items: [],
    //     };

    //     widgetCategoryList.forEach(category => {
    //       if (widgetsByCategory[category.key]) {
    //         widgetsMenu.items.push({
    //           label: category.label,
    //           items: widgetsByCategory[category.key].map(widget => ({
    //             ...widget,
    //             label: widget.headline,
    //             callback: (event, widget) => this.selectWidget(widget, reportType)
    //           }))
    //         });
    //       }
    //     });

    //     runInAction(() => {
    //       this.widgetsMenu = widgetsMenu;
    //     });

    // }

    // This function is used to fetch data for widgets that has an independant url / api call
    async fetchDynamicWidgetData(widgetItem, customerId, portfolioId, reportType, period) {
      const { FromDate, ToDate } = getDateRangeByNumberFromPeriod(period, dataStore.calcDate)
      const params = {
          CustomerID: Number(customerId),
          PortfolioName: portfolioId,
          FromDate,
          CalcName: widgetItem.id,
          ToDate,
      };

      try {
        const response = await call(`${API_URL}/${widgetItem.independentFetchingURL}`, params);
        let data
        if(response.message.ErrorCode === 0) {
          data = response.message
        } else {
          data = {
            BM: [],
            CalcName: widgetItem.id,
            CustomerID: customerId,
            ErrorCode: response.message.ErrorCode,
            ErrorText: response.message.ErrorText,
            HistData: [],
            InvestAmount: 0,
            PortfolioName: portfolioId
          }
        }
        this.injectWidgetData(widgetItem.id, data);
      } catch (error) {
          snackbarStore.handleOpen(error.toString(), 'error');
      }
  }
}

const store = (window.portfolioWidgetsStore = new PortfolioWidgetsStore());

export default store;