/* eslint-disable no-console */
import { API_URL, REQUEST_RESPONSE_CODES } from 'api/constants';
import axios from "axios";
import { LOGIN_PAGE_PATH } from 'routes/privateRoutes';
import { getToken, removeToken } from 'utils/localStorage';

export const internalapiCall = async function(method, url, data) {
    const instance = () => {
        const options = {
            baseURL: API_URL,
            headers: {
                "Token": getToken(),
                // "Token": localStorage.getItem("TOKEN"),
                // "UserID": localStorage.getItem("UserID"),
                // "OrganisationID": localStorage.getItem("OrganisationID"),
                'Access-Control-Allow-Origin': "*",
                "Accept": "*/*",
                "Content-Type": "application/json"
            }
        }

        return axios.create(options);
    }

    const request = instance();
    try {

        const response = await request({ method, url, data })
    
        if (response.status === 403) {
            removeToken();
            document.location.replace(LOGIN_PAGE_PATH);
        }
    
        return response;
    } catch (error) {
        if(error.response && error.response.status === 403) {
            removeToken();
            document.location.replace(LOGIN_PAGE_PATH);
        }
        console.error(error)
    }
}

async function call (url, data, method = "POST") {
    const instance = () => {
        const accessToken = getToken();

        const options = {
            baseURL: API_URL,
            headers: {
                'Access-Control-Allow-Origin': "*",
                "Accept": "*/*",
                "Content-Type": "application/json"
            }
        }

        if(accessToken) {
            options.headers.Token = accessToken;
        }


        return axios.create(options);
    }

    const request = instance();
    const response = await request({ method, url, data })

    const isLoggedIn =
        response.data.ErrorCode !== REQUEST_RESPONSE_CODES.TOKEN_EXPIRE_ERROR_CODE &&
        response.data.ErrorCode !== REQUEST_RESPONSE_CODES.INVALID_TOKEN_ERROR_CODE &&
        response.data.ErrorCode !== REQUEST_RESPONSE_CODES.LOGIN_HAS_EXPIRED_ERROR_CODE &&
        response.data.ErrorCode !== REQUEST_RESPONSE_CODES.INVALID_TOKEN_ERROR_CODE &&
        response.data.ErrorCode !== REQUEST_RESPONSE_CODES.MISSING_TOKEN &&
        response.data.ErrorCode !== REQUEST_RESPONSE_CODES.MISSING_TOKEN_IN_HEADER

        if (!isLoggedIn) {
            removeToken();
            document.location.replace(LOGIN_PAGE_PATH);
        }

        if(response.status === 200 && (response.data.ErrorCode === REQUEST_RESPONSE_CODES.REQUEST_SUCCESS_CODE || response.data.ErrorCode === undefined)) {
            return { success: true, message: response.data, meta: response }
        }
        // eslint-disable-next-line no-else-return
        else {
            console.table([
                {"Failed request": response.config.url},
                { "params": data },
                { "status": response.status },
                { "res data": response.data },
            ])
            return { success: false, message: "error in request", meta: response }
        }
}

export default call